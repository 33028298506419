import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TextLight, TextMedium } from "../../../base/BaseText";
import { ReactComponent as PaymentMethodIcon } from "../../../images/true/icon-payment-method.svg";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseInformationBox from "../../../base/BaseInformationBox";
import { useTranslation, Trans } from "react-i18next";
import { savePaymentMethod } from "../state/operators/contactsOperator";
import { addJourneyMessages, sleep } from "../../../helpers/addJourneyMessages";
import createMessage from "../../../helpers/createMessage";

const SelectPaymentMethod = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [disabled, setDisabled] = useState(false)
    const [clicked, setClicked] = useState("");
    const { caseType } = useSelector((state) => state.journeyMessages);
    const perilType = caseType && caseType.replace(/\s+/g, "");

    const fee = useSelector((state) => state.claim.payment.serviceFee.TotalAmount || state.claim.payment.serviceFee[perilType]?.TotalAmount);

    const handleCOD = async () => {
        setClicked("COD");
        setDisabled(true);

        dispatch(
            addJourneyMessages([
              createMessage("TEXT", "user", {
                key: "CODButton",
              })
            ])
          );

        await dispatch(savePaymentMethod("COD"));
    }

    const handleCreditCard = async () => {
        setClicked("CRE");
        setDisabled(true);

        dispatch(
            addJourneyMessages([
              createMessage("TEXT", "user", {
                key: "CCButton",
              })
            ])
          );

        await dispatch(savePaymentMethod("CRE"));
    }

    return (
        <div className="app-PrepareRequirements">
            <BaseCard className="space-y-4">
                <PaymentMethodIcon className="app-BaseIcon-center" />

                <BaseLabel className="my-2">
                    <Trans i18nKey={`Payment.SelectPaymentMethod.Title`}>
                        Please note that the total amount charged to you will be <strong>THB{{ fee }}(inclusive of VAT) is applicable.</strong>
                    </Trans>
                </BaseLabel>

                <BaseInformationBox>
                    <span className="text-20px text-app-nova inline-block leading-none ">
                        <Trans i18nKey="Payment.SelectPaymentMethod.Information" />
                    </span>
                </BaseInformationBox>

                <BaseButtonGroup>
                    <BaseButton
                        className="mt-0 px-5 bg-white border-app-primary text-app-primary w-fit"
                        text={t("CODButton")}
                        onClick={handleCOD}
                        disabled={disabled}
                        clicked={clicked === "COD"}
                    />
                    {/*<BaseButton
                        className="mt-0 px-5 bg-white border-app-primary text-app-primary w-fit"
                        text={t("CCButton")}
                        onClick={handleCreditCard}
                        disabled={disabled}
                        clicked={clicked === "CRE"}
                    />*/}
                </BaseButtonGroup>
            </BaseCard>
        </div>
    );
};

export default SelectPaymentMethod;
