import {
  addJourneyMessages,
  sleep,
} from "../../../../helpers/addJourneyMessages";
import createMessage from "../../../../helpers/createMessage";
import {
  PROGRESS_DATA,
  API_PROGRESS,
  COMPONENTS,
  ACTIVITY,
} from "../../../../helpers/constants";
import {
  updateFlowProgress,
  updateApiProgress,
  clearApiProgress,
  serviceUnavailable,
} from "../../../../actions/flowWindow";
import { updateContactDetails } from "../actions/contactsApi";
import updateVisitor from "../../../Appsync/updateVisitor";
import { getPciToken } from "../actions/paymentApi";
import { getMaskedData, isEmpty } from "../../../../helpers/formatUtils";
import { setContactDetails } from "../../../Validation/state/reducers";
import { confirmToCancelRequest } from "./cancelRequestOperator";
import { EnumEnquiryModules } from "../../../../components/EnquiryOptions/Constants";
import { submitCODPayment } from "./paymentOperator";
import { confirmationOfDetails } from "./scheduleOperator";
import { setPaymentMethod } from "../../../RepairClaim/state/reducers/paymentReducer";
import i18n from 'i18next';
import { DEVICE } from "../../../../helpers/constants";

export const isClaimInWarranty = (store) => {
  const enquiryOption = store.journeyMessages.enquiryOption;
  /**
   * Determining warranty-type 
   * If claim is in resume state, get warranty-type from serviceRequest
   * otherwise get warranty-type from process-incident response ie fresh claim
   */
  if(enquiryOption === EnumEnquiryModules.ResumeRequest){
    const { WarrantyType: warrantyTypeResume } = store.serviceRequest.serviceRequestDetails;
    return warrantyTypeResume === "IW"
  }

  const { WarrantyType: warrantyTypeFresh } = store.claim?.termsAndConditions?.processIncident?.CreateIncidentResults?.CustomerCase?.ServiceRequest;
  return warrantyTypeFresh === "IW"
}

export const isDeviceMakeCCPaymentOnly = (deviceMake) =>{
  // return (deviceMake.toUpperCase() === DEVICE.REALME|| 
  // deviceMake.toUpperCase() === DEVICE.OPPO|| 
  // deviceMake.toUpperCase() === DEVICE.VIVO)
  return false;
}

export const submitConfirmationOfContactDetails = (status, isMobile) => async (
  dispatch,
  getStore
) => {
  dispatch(addJourneyMessages([createMessage("TEXT", "user", status)]));
  const isCorrect = ["Yes", "Keep"].includes(status);

  let {
    phoneNumber: PhoneNumber,
    emailAddress: EmailAddress,
  } = getStore().validation.inputData;
  const { CacheId } = getStore().session.sessionData.Configurations;
  const { ServiceRequestId } = getStore().serviceRequest.serviceRequestDetails;
  const deviceMake = getStore().claim.deviceConfirmation.selectedAsset.Make.Name


  const isInWarranty = isClaimInWarranty(getStore());

  let showComponent = "";
  let module = "CONTACT_CONFIRMATION";
  let msg = "",
    msg_success = "";

  // if (isMobile) {
  //   msg = i18n.t("Constants.API_PROGRESS.UPDATE_CONTACT_NUMBER");
  //   msg_success = i18n.t("Constants.API_PROGRESS.UPDATE_CONTACT_NUMBER_SUCCESS");
  // } else {
  //   msg = i18n.t("Constants.API_PROGRESS.UPDATE_CONTACT_EMAIL");
  //   msg_success = i18n.t("Constants.API_PROGRESS.UPDATE_CONTACT_EMAIL_SUCCESS");
  // }

  msg = i18n.t("Constants.API_PROGRESS.UPDATE_CONTACT_DETAILS");
  msg_success = i18n.t("Constants.API_PROGRESS.UPDATE_CONTACT_DETAILS_SUCCESS");

  if (isCorrect && !isMobile) {
    await dispatch(updateApiProgress(msg, 30, COMPONENTS.UPDATE_CONTACT));
    // const contactResponse = await dispatch(
    //   updateContactDetails(CacheId, ServiceRequestId, PhoneNumber, EmailAddress)
    // ).catch((err) => dispatch(serviceUnavailable()));
    // if (isEmpty(contactResponse)) return;
    let contactResponse = null;
    let retrycount = 0;
    while (retrycount < 2) {
      contactResponse = await dispatch(updateContactDetails(CacheId, ServiceRequestId, PhoneNumber, EmailAddress))
      .catch((err) => { return null }
      );

      if (contactResponse === null) {
        retrycount++;
      } else {
        retrycount = 2;
      }
    }
    // not allowing to go ahead if response is empty
    if (isEmpty(contactResponse)) {
      dispatch(serviceUnavailable())
      return;
    }

    await dispatch(
      updateApiProgress(msg_success, 40, COMPONENTS.UPDATE_CONTACT, 30)
    );

    /**
     * Calling serviceOrder to shippingOrder API after contactPointAPI success
     */
    // await dispatch(confirmationOfDetails())
    let res = await dispatch(confirmationOfDetails())
    if (!res) {
      return false;
    }

    await sleep(1000);
    await dispatch(clearApiProgress());
  }

  if (isMobile) {
    const enrolledEmailAddress = getStore().validation.verification.EmailAddress;
    const isEmailAvailable =
      enrolledEmailAddress !== false &&
      !isEmpty(enrolledEmailAddress) &&
      enrolledEmailAddress !== "EMAIL_NOT_AVAILABLE";

    showComponent = isCorrect
      ? isEmailAvailable
        ? "ConfirmEmail"
        : "ChangeEmail"
      : "ChangeMobileNumber";
  } else {
    if(isCorrect && isDeviceMakeCCPaymentOnly(deviceMake)){
      await dispatch(savePaymentMethod("CRE"));
    }
    else{
      //showComponent = isCorrect ? "SubmitCardDetails" : "ChangeEmail";
      showComponent = isCorrect ? "SelectPaymentMethod" : "ChangeEmail";
      module = isCorrect ? "PAYMENT" : module;
      if (isCorrect && isInWarranty) {
        await dispatch(submitCODPayment());
        return;
      } else if (isCorrect) {
        // dispatch(getPciToken(CacheId)).catch((err) =>
        //   dispatch(serviceUnavailable())
        // );

        await dispatch(
          addJourneyMessages([
            createMessage("TEXT", "system", {
              key: "SystemMessage.PaymentProcessing",
            }),
          ])
        );

        const initial = getStore().journeyMessages.flowProgress.percentage;
        dispatch(
          updateFlowProgress(
            i18n.t("Constants.PROGRESS_DATA.PAYMENT.title"),
            i18n.t("Constants.PROGRESS_DATA.PAYMENT.count"),
            initial
          )
        );
      }
    }
  }
  dispatch(
    addJourneyMessages([createMessage(module, "system", { showComponent })])
  );
};

export const submitChangesInContactDetails = (contactData, isMobile) => async (
  dispatch,
  getStore
) => {
  let maskedData = "";
  if (isMobile) {
    maskedData = getMaskedData(contactData, "MDN");
  } else {
    maskedData = getMaskedData(contactData, "EMAIL");
  }
  dispatch(addJourneyMessages([createMessage("TEXT", "user", maskedData)]));

  const { CacheId } = getStore().session.sessionData.Configurations;
  const { ServiceRequestId } = getStore().serviceRequest.serviceRequestDetails;
  const isInWarranty = isClaimInWarranty(getStore());
  const deviceMake = getStore().validation.agreement?.ClaimedAsset?.Make?.Name ?? getStore().claim.deviceConfirmation?.selectedAsset?.Make?.Name
  
  let {
    phoneNumber: PhoneNumber,
    emailAddress: EmailAddress,
  } = getStore().validation.inputData;
  let showComponent = "";
  let module = "CONTACT_CONFIRMATION";
  let msg = "",
    msg_success = "",
    component = "";
  if (isMobile) {
    const enrolledEmailAddress = getStore().validation.verification.EmailAddress;
    const isEmailAvailable =
      enrolledEmailAddress !== false &&
      !isEmpty(enrolledEmailAddress) &&
      enrolledEmailAddress !== "EMAIL_NOT_AVAILABLE";
    showComponent = isEmailAvailable ? "ConfirmEmail" : "ChangeEmail";
    PhoneNumber = contactData;
    msg = i18n.t("Constants.API_PROGRESS.UPDATE_CONTACT_NUMBER");
    msg_success = i18n.t("Constants.API_PROGRESS.UPDATE_CONTACT_NUMBER_SUCCESS");
    component = COMPONENTS.UPDATE_CONTACT;
  } else {
    EmailAddress = contactData;

    if(isDeviceMakeCCPaymentOnly(deviceMake)){
      await dispatch(savePaymentMethod("CRE"));
    }
    else {
      showComponent = "SelectPaymentMethod";
      module = "PAYMENT";
      msg = i18n.t("Constants.API_PROGRESS.UPDATE_CONTACT_DETAILS");
      msg_success = i18n.t("Constants.API_PROGRESS.UPDATE_CONTACT_DETAILS_SUCCESS");
      component = COMPONENTS.UPDATE_CONTACT;
    }
  }

  if (!isMobile) {
    await dispatch(updateApiProgress(msg, 40, component));
    // const contactResponse = await dispatch(
    //   updateContactDetails(CacheId, ServiceRequestId, PhoneNumber, EmailAddress)
    // ).catch((err) => dispatch(serviceUnavailable()));
    // // return if response is empty
    // if (isEmpty(contactResponse)) return;
    let contactResponse = null;
    let retrycount = 0;
    while (retrycount < 2) {
      contactResponse = await dispatch(updateContactDetails(CacheId, ServiceRequestId, PhoneNumber, EmailAddress))
        .catch((err) => { return null }
        );

      if (contactResponse === null) {
        retrycount++;
      } else {
        retrycount = 2;
      }
    }
    // not allowing to go ahead if response is empty
    if (isEmpty(contactResponse)) {
      dispatch(serviceUnavailable())
      return;
    }

    /**
     * Calling serviceOrder to shippingOrder API after contactPointAPI success
     */
    // await dispatch(confirmationOfDetails())
    let res = await dispatch(confirmationOfDetails())
    if (!res) {
      return false;
    }
  }

  await dispatch(updateContactDetailsData(PhoneNumber, EmailAddress));
  dispatch(
    updateVisitor({
      lastActivity: isMobile
        ? ACTIVITY.UPDATE_CONTACT_NUMBER
        : ACTIVITY.UPDATE_CONTACT_EMAIL,
    })
  );
  const initial = getStore().journeyMessages.flowProgress.percentage;

  if (!isMobile && isInWarranty) {
    await dispatch(updateApiProgress(msg_success, 100, component, 40));
    await sleep(1000);
    await dispatch(clearApiProgress());
    console.log("IS IN WARRANTY")
    dispatch(submitCODPayment());
    return;
  }
  if (module === "PAYMENT") {
    const { CacheId } = getStore().session.sessionData.Configurations;
    const { caseType } = getStore().journeyMessages;
    const perilType = caseType && caseType.replace(/\s+/g, "");
    const fee = getStore().claim.payment.serviceFee.TotalAmount || getStore().claim.payment.serviceFee[perilType]?.TotalAmount;
    dispatch(getPciToken(CacheId, fee)).catch((err) =>
      dispatch(serviceUnavailable())
    );
    await dispatch(
      addJourneyMessages([
        createMessage("TEXT", "system", {
          key: "SystemMessage.PaymentProcessing",
        }),
      ])
    );
    dispatch(
      updateFlowProgress(
        i18n.t("Constants.PROGRESS_DATA.PAYMENT.title"),
        i18n.t("Constants.PROGRESS_DATA.PAYMENT.count"),
        initial
      )
    );
  }
  await dispatch(updateApiProgress(msg_success, 100, component, 40));
  await sleep(1000);
  await dispatch(clearApiProgress());
  dispatch(
    addJourneyMessages([createMessage(module, "system", { showComponent })])
  );
};

export const updateContactDetailsData = (phone = "", email = "") => async (
  dispatch,
  getStore
) => {
  let phoneNumber = phone,
    emailAddress = email;
  const { PhoneNumber, EmailAddress } = getStore().validation.agreement;
  if (isEmpty(phone)) {
    phoneNumber = PhoneNumber;
  }
  if (isEmpty(email) && EmailAddress !== false && !isEmpty(EmailAddress)) {
    emailAddress = EmailAddress;
  }
  dispatch({
    type: setContactDetails.toString(),
    payload: {
      phoneNumber,
      emailAddress,
    },
  });
};

export const cancelledContactUpdate = () => async (dispatch) => {
  await dispatch(addJourneyMessages([createMessage("TEXT", "user", "Cancel")]));
  dispatch(confirmToCancelRequest("", "Cancelled Contact Update", true));
};


export const isInWarrantyClaim = (enquiryOption, warrantyType) => {
  if(enquiryOption === EnumEnquiryModules.InWarrantyRequest){
    return true;
  }

  if(enquiryOption === EnumEnquiryModules.ResumeRequest && warrantyType === "IW"){
    return true;
  }

  return false;
}

export const savePaymentMethod = (method) => async (
  dispatch,
  getStore
) => {
  // await dispatch({
  //   type: setPaymentMethod.toString(),
  //   payload: {
  //     method
  //   },
  // });

  // let module, showComponent;

  // if (method === "CRE") {
  //   const { CacheId } = getStore().session.sessionData.Configurations;

  //   await dispatch(
  //     updateApiProgress(i18n.t("Constants.API_PROGRESS.DATA"), 40, COMPONENTS.PAYMENT)
  //   );

  //   const { caseType } = getStore().journeyMessages;
  //   const perilType = caseType && caseType.replace(/\s+/g, "");
  //   const fee = getStore().claim.payment.serviceFee.TotalAmount || getStore().claim.payment.serviceFee[perilType]?.TotalAmount;
  //   await dispatch(getPciToken(CacheId, fee)).catch((err) =>
  //     {
  //       dispatch(serviceUnavailable()); 
  //       return;
  //     }
  //   );

  //   await dispatch(
  //     updateApiProgress(i18n.t("Constants.API_PROGRESS.DATA_SUCCESS"), 100, COMPONENTS.PAYMENT)
  //   );

  //   module = "PAYMENT";
  //   showComponent = "SubmitCardDetails";

  //   dispatch(clearApiProgress());

  //   dispatch(
  //     addJourneyMessages([createMessage(module, "system", { showComponent })])
  //   );
  // } else {
  //   await dispatch(submitCODPayment());
  // }

  await dispatch(submitCODPayment());
}

export const showPaymentMethods = () => async (
  dispatch,
  getStore
) => {
  const showComponent = "SelectPaymentMethod";
  const module = "PAYMENT";
  const initial = getStore().journeyMessages.flowProgress.percentage;
  const msg_success = i18n.t("Constants.API_PROGRESS.UPDATE_CONTACT_DETAILS_SUCCESS");
  const component = COMPONENTS.UPDATE_CONTACT;
  const isInWarranty = isClaimInWarranty(getStore());


  const { CacheId } = getStore().session.sessionData.Configurations;
  const { caseType } = getStore().journeyMessages;
  const perilType = caseType && caseType.replace(/\s+/g, "");
  const fee = getStore().claim.payment.serviceFee.TotalAmount || getStore().claim.payment.serviceFee[perilType]?.TotalAmount;
  await dispatch(getPciToken(CacheId, fee)).catch((err) =>
    dispatch(serviceUnavailable())
  );
  await dispatch(
    addJourneyMessages([
      createMessage("TEXT", "system", {
        key: "SystemMessage.PaymentProcessing",
      }),
    ])
  );
  dispatch(
    updateFlowProgress(
      i18n.t("Constants.PROGRESS_DATA.PAYMENT.title"),
      i18n.t("Constants.PROGRESS_DATA.PAYMENT.count"),
      initial
    )
  );

  await dispatch(updateApiProgress(msg_success, 100, component, 40));
  await sleep(1000);
  await dispatch(clearApiProgress());
  dispatch(
    addJourneyMessages([createMessage(module, "system", { showComponent })])
  );
}